import React, { useEffect, useState} from 'react';
import AOS from 'aos';
import { useSpring, animated } from 'react-spring';
import './style.css';
import logo from './img/logo.png';
import tw from './img/tw.png';
import tele from './img/tele.png';
import tron from './img/tron.png';
import g1m from '../features/img/video/v1c.mp4'
import g2m from '../features/img/video/v2c.mp4'
import ReactPlayer from 'react-player'
import cover1 from './img/cover1.png'

function Header() {
  const [scroll, setScroll] = useState()
  const [{ scrollY }, setScrollY] = useSpring(() => ({ scrollY: 0 }))

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];


  const handleScroll = () => {
    const newScrollY = window.scrollY <= 250 ? window.scrollY : 250;
    setScrollY({ scrollY: newScrollY });
    setScroll(window.scrollY)
  }


  useEffect(() => {
    AOS.refresh();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const [isLoading, setIsLoading] = useState(true);

  const handleOnReady = () => {
    setIsLoading(false);
  };


  return (
    <div className="header" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
      <div className='nav'>
        <div className='logo'>
          <img src={logo} alt=''/>
        </div>
        <div className='menu'>
          <ul>
            <li><a href='#Features'>Features</a></li>
            <li><a href='#Weekly'>Weekly Tournament</a></li>
            <li><a href='#Blockchain'>Blockchain Integration</a></li>
          </ul>
        </div>
        <div className='social'>
          <ul>
            <li>
              <img src={tron} alt=''/>
            </li>
            <li>
              <a href='https://t.me/coconutchicken_trx' target='_blank' rel="noreferrer">
                <img src={tw} alt=''/>
              </a>
            </li>
            <li>
              <a href='https://t.me/coconutchicken_trx' target='_blank' rel="noreferrer">
                <img src={tele} alt=''/>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <animated.div className="content" style={{ transform: scrollY.interpolate(y => `translate(0, ${(y / 45)}px)`) }}>
        <h2 data-aos="fade-up" data-aos-delay="100">The Ultimate TRON Blockchain <br/><span style={{color: 'rgb(255 86 15)'}}>Powered Online Shooter</span></h2>
        <p data-aos="fade-up" data-aos-delay="200">
          ChickenWARS is an online multiplayer GameFi project built on the TRON blockchain. 
          The game features characters inspired by various TRON projects and internet memes. 
          Initially available for Windows, ChickenWARS is expanding its reach with upcoming releases for MacOS, iOS, 
          and Android platforms, offering an engaging and cross-platform gaming experience.

          <div data-aos="fade-up" data-aos-delay="300">
            <button 
              onClick={() => window.open('https://chickenwars.io/chickenwarslauncher.exe')} 
              className='button' 
              style={{marginTop:'20px'}}
            >Start game</button>   
          </div>
        </p>

            
      </animated.div>


      <animated.div 
        className='kura' 
        style={{ transform: scrollY.interpolate(y => `translate(0, ${(y / 6)}px)`) }}
      >
      </animated.div>
      <animated.div 
        className='konsola' 
        style={{ transform: scrollY.interpolate(y => `translate(0, ${(y / 12)}px)`) }}
      >
      </animated.div>
      <animated.div 
        className='kibice' 
        style={{ transform: scrollY.interpolate(y => `translate(0, ${(y / 30)}px)`) }}
      >
      </animated.div>


      <animated.div 
        className='swiatlo' 
        style={{ transform: scrollY.interpolate(y => `translate(0, ${(y / 12)}px)`) }}
      ></animated.div>


        <animated.div
          className='baner-video-1'
        >
          <ReactPlayer url={g1m} playing={!isLoading} width="100%" height="auto" muted loop onReady={handleOnReady}/>
        </animated.div> 


      <animated.div
        className='baner-video-2'
      >
        <ReactPlayer url={g2m} playing={!isLoading} width="100%" height="auto" muted loop onReady={handleOnReady}/>
      </animated.div>

      {/* <animated.div 
        className='baners' 
        style={{ transform: scrollY.interpolate(y => `translate(0, ${(y / 48)}px)`) }}
      >
      </animated.div> */}


    </div>
  );
}

export default Header;
